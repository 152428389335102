:root{
    --color1:#333333;
    --color2: #E8431E;
    --color3: #FFFFFF;
    --color4: #383838;
    --color5:  #676666;
    --color6: #e6dbd8;
    --font1: 'Proxima Nova';
    
  
  }