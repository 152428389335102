@import "Colors.css";

.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found h1 {
    margin-top: -50px;
    font-size: 5rem;
    margin-bottom: 1rem;
    color: var(--color2);
  }
  
  .not-found p {
    font-size: 2rem;
    color: #666;
  }
  