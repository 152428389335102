@import "Colors.css";

.mainTitle {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: left;
  color: var(--color1);
  margin-top: 3rem;


  @media (max-width: 1369px) {
    font-size: 2.5rem;
  }
}

.left {
  flex: 1;
  padding: 30px;
  width: 25%; 
}

.main {
  display: flex;
  justify-content: center;
}

.right {
  flex: 2;
  padding: 30px;
  width: 75%; 
}

.video {
  margin: 5%;
  text-align: center;
}