@keyframes slideIn {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
@keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .slideUp {
    animation: slideUp 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
  }

  .slideIn {
    animation: slideIn 1.5s ease-out forwards;
  }
  
  .hidden {
    opacity: 0;
  }