@import "Colors.css";

#chk{
	display: none;
}
.signup{
	position: relative;
	width:100%;
	height: 100%;
}
.login{
	height: 95%;
	background: var(--color3);
	border-radius: 60% / 10%;
	
	transform: translateY(-180px);
	transition: .8s ease-in-out;
}
.login label{
	color: var(--color2);
	transform: scale(.6);
}

#chk:checked ~ .login{
	transform: translateY(-500px);
}
#chk:checked ~ .login label{
	transform: scale(1);	
}
#chk:checked ~ .signup label{
	transform: scale(.6);
}