@import "Colors.css";

.mainTitle {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: left;
  color: var(--color1);
  margin-top: 3rem;

  @media (max-width: 1369px) {
    font-size: 2.5rem;
  }
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    display: flex;
    flex-direction: column;
    flex: 1;
}

footer {
    margin-top: auto;
}
  
.section {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
    }
}
  
.filter {
    width: 300px; 
    min-width: 300px;
    max-width: 300px; 
    margin-right: 5%;
    flex-grow: 0;

    @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }
}
 
main {
    flex-grow: 1;
}

main#EventDetails {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; /* Centrer verticalement les éléments */
    flex-grow: 1;
}

.videos {
    margin: 5%;
    text-align: center;
}

  