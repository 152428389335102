#iframe-container {
    width: 100vw;
    height: 90vh;
    resize: both;
    overflow: auto;
    margin-top: 5%;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    resize: both;
    overflow: auto;
  }

